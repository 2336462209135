export const environment = {
    production: true,
    apiUrl: 'https://digibord.thiememeulenhoff.nl',
    sentryDsn:
        'https://89f74c0352c44a7d995f04fd8faba288@o37556.ingest.sentry.io/5728370',
    environment: 'prod',
    googleAnalyticsCode: 'G-GPKDYW6S0T',
    thasToken:
        '6ad3383d7d09859c24f01f98a2e425ec60683596ef0b52a698991239dedf3330',
};
